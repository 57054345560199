// paymentSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSurvey,
  getSurvey,
  submitSurveyFinish,
} from "../app/api/survey.js";
import { CREATE_SURVEY, GET_SURVEY, FINISH_SURVEY } from "./types";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";
import { fetchUserData } from "./userSlice";

export const createUserSurvey = createAsyncThunk(
  CREATE_SURVEY,
  async (payload, { rejectWithValue, dispatch }) => {
    const data = await createSurvey(payload);
    if (data?.status === 200) {
      await dispatch(getUserSurvey());
    } else {
      return rejectWithValue("Survey submition failed");
    }
  }
);

export const getUserSurvey = createAsyncThunk(
  GET_SURVEY,
  async (payload, { rejectWithValue, dispatch }) => {
    const data = await getSurvey();
    if (data?.status === 200 && data?.surveypayload) {
      return data?.surveypayload;
    }
    return rejectWithValue("fetching user Survey failed");
  }
);

export const SurveyComplete = createAsyncThunk(
  FINISH_SURVEY,
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const customerId = state?.survey?.userSurvey?.[0]?.customer;
    const responce = await submitSurveyFinish(customerId);
    if (responce?.status === 200) dispatch(fetchUserData());
  }
);

const initialState = {
  error: null,
  loading: false,
  userSurvey: null,
};
// Slice for authentication and user data
const paymentSlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetSurvey: (state) => {
      localStorage.removeItem("surveyAnswers");
      localStorage.removeItem("surveyQuestions");
      localStorage.removeItem("survey_address_form");
      localStorage.removeItem("survey_autoComplete_address");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action?.payload?.user,
        };
      })
      .addCase(createUserSurvey?.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createUserSurvey?.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(createUserSurvey?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getUserSurvey?.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserSurvey?.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.userSurvey = action?.payload;
      })
      .addCase(getUserSurvey?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(SurveyComplete?.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(SurveyComplete?.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(SurveyComplete?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});
export const { resetSurvey } = paymentSlice?.actions;

export default paymentSlice?.reducer;
