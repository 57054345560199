import { toast } from "react-toastify";
import fetchWithHeaders from "../../fetchInterceptor";

export const deleteCardApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}payment/card/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "DELETE",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return { data };
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return { error };
  }
};
export const updateCardApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}payment/card/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "PUT",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error;
  }
};
export const paymentCardApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}payment/card/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "POST",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error;
  }
};
export const verifyCardApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}payment/verify-card/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "POST",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error;
  }
};
export const paymentConfirmApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}payment/payment/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "POST",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error;
  }
};

export const capturePaymentOrder = async (payload) => {
  const url = `${process.env.BACKEND_URL}events/capture_payment/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "POST",
    });
    if (response.status == 200) {
      toast("Order Placed Succesfully", { type: "success" });
      return response;
    } else if (response.status == 500) {
      toast("Something went wrong with the payment", { type: "error" });
    }
  } catch (e) {
    toast(`Something went wrong ${e}`, { type: "error" });
  }
};
