import { ARABIC } from "../../../public/Constants/EnumConstants";

export const CLOSE_BUTTON_CLASS =
  "bg-transparent rounded-lg  p-1.5 absolute  top-2.5 inline-flex items-center ";
export const CLOSE_BUTTON_CLASS_ARABIC =
  "bg-transparent rounded-lg  p-1.5 absolute  top-2.5 left-2.5inline-flex items-center ";

export const LOGO_CLASS = "flex justify-center items-center mx-4";
export const SERENA_LOGO_CLASS = "flex justify-center items-center mx-auto";
export const NAVBAR_CLASS =
  " bg-white dark:bg-White border-gray-200 px-3 md:px-4 lg:px-6 py-3  flex flex-wrap justify-between items-center  max-w-full";
export const SERENA_NAVBAR_CLASS =
  " bg-white dark:bg-White border-gray-200 px-3 md:px-4 lg:px-6 py-3 max-w-full";
export const LOGO_IMAGE_CLASS = "h-6 w-full sm:h-7 md:h-9";
export const MENU_IMAGE_CLASS = "h-full w-full";
export const LINKS_AND_BUTTONS_CONTAINER =
  "flex flex-wrap justify-between items-center  max-w-full";
export const LINKS_CONTAINER =
  "hidden justify-between items-center w-full xl:flex lg:w-auto mx-auto ";
export const BUTTONS_CONTAINER = "hidden xl:flex ";

export const HEADER_UNORDERED_LIST_CLASS =
  "flex flex-col mt-4 font-medium lg:flex-row  lg:mt-0 px-14";
export const MENU_ICON_CLASS =
  "inline-flex items-center  text-sm  rounded-lg xl:hidden focus:outline-none focus:ring-2 ";
export const SERENA_MENU_ICON_CLASS =
  "inline-flex items-center  text-sm  rounded-lg focus:outline-none focus:ring-2 ";
export const DRAWER_BUTTON_CONTAINER = "w-full flex justify-center";
export const SPACE_Y_2 = "space-y-2";
export const CANCEL_ICON_CONTAINER = "-mb-11";
export const DRAWER_CONTAINER =
  "block xl:hidden fixed z-[600] h-screen top-0 p-4 overflow-y-auto bg-white w-max ";
export const SERENA_DRAWER_CONTAINER =
  "block fixed z-[600] h-screen top-0 p-4 overflow-y-auto bg-white w-max ";
export const DROPDOWN_CLASS = "dropdown cursor-pointer";
export const DRAWER_PARENT_CONTAINER =
  " fixed overflow-hidden z-[500] bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out  ";
export const DASHBOARD_DRAWER_CONTAINER =
  " fixed z-[600] h-screen top-0 right-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";
export const DASHBOARD_DRAWER_CONTAINER_AR =
  " fixed z-[600] h-screen top-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";

export const DASHBOARD_MENU_CONTAINER =
  "dropdown-menu hidden absolute z-[600] border rounded h-fit right-0 px-1 overflow-y-auto bg-white w-max drop-shadow-4xl";
export const DASHBOARD_MENU_CONTAINER_AR =
  "dropdown-menu hidden fixed z-[600] border rounded h-fit left-4 px-1 overflow-y-auto bg-white w-max drop-shadow-4xl";
export const DRAWER_INNER_CONTAINER = "py-4 overflow-y-none";
export const SWITCHER_LINKS_CONTAINER =
  "hidden justify-between items-center w-full xl:flex lg:w-auto md:ml-3 ";
export const SWITCHER_LINKS_CONTAINER_ARABIC =
  "hidden justify-between items-center w-full xl:flex lg:w-auto md:mr-3 ";
export const MARGING_3 = "mx-3";
export const DRAWER_OPEN_TRANSITION =
  " transition-opacity opacity-100 duration-500  translate-x-0  ";
export const DASHBOARD_DRAWER_CLOSE_TRANSITION =
  " transition-all delay-500 duration-500 opacity-0  translate-x-full  ";
export const DRAWER_CLOSE_TRANSITION =
  " transition-all delay-500 duration-500 opacity-0  -translate-x-full  ";

export const getDashboardContainerClass = (lang) =>
  lang !== ARABIC ? DASHBOARD_DRAWER_CONTAINER : DASHBOARD_DRAWER_CONTAINER_AR;
