import fetchWithHeaders from "../../fetchInterceptor";

export const createSurvey = async (Payload) => {
  const url = `${process.env.BACKEND_URL}core/CreateSurvey/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(Payload),
      method: "POST",
    });
    const data = await response?.json();
    return { ...data, status: response?.status };
  } catch (error) {
    return error?.response?.data;
  }
};

export const getSurvey = async () => {
  const url = `${process.env.BACKEND_URL}core/GetSurvey/`;
  try {
    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    const data = await response?.json();
    return { ...data, status: response?.status };
  } catch (error) {
    return error?.response?.data;
  }
};

export const submitSurveyFinish = async (id) => {
  const url = `${process.env.BACKEND_URL}api/v1/email/${id}/`;
  try {
    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    const data = await response?.json();
    return { data, status: response?.status };
  } catch (error) {
    return error?.response?.data;
  }
};

export const resendVerificationLink = async (id) => {
  const url = `${process.env.BACKEND_URL}core/resend-verification-email/`;
  try {
    const response = await fetchWithHeaders(url, {
      method: "GET",
    });

    return await response?.json();
  } catch (error) {
    return error?.response?.data;
  }
};

export const promoApply = async (code) => {
  const url = `${process.env.BACKEND_URL}payment/check-discount/${code}`;

  try {
    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    switch (response?.status) {
      case 200:
        const data = await response.json();
        if (data?.expired == true) {
          localStorage.removeItem("promo_code");
          return { error: "Expired Code 😱" };
        }
        localStorage.setItem("promo_code", JSON.stringify(data));
        return data;
        break;
      case 400:
      case 404:
        localStorage.removeItem("promo_code");
        return { error: "Invalid Code !" };
        break;
      default:
        localStorage.removeItem("promo_code");
        return { error: "Something went wrong" };
        break;
    }
  } catch (error) {
    localStorage.removeItem("promo_code");
    switch (error?.response?.status) {
      case 400:
      case 404:
        return { error: "Invalid Code !" };
        break;
      default:
        return { error: "Something went wrong" };
        break;
    }
  }
};

export const getEnglishTranslation = async (val) => {
  const url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.GOOGLE_PLACE_API_KEY}`;
  const payload = {
    q: [val?.building, val?.street_number || val?.street, val?.city, val?.area],
    target: "en",
    format: "text",
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const data = await response?.json();
    return {
      building: data?.data?.translations?.[0]?.translatedText,
      street_number: data?.data?.translations?.[1]?.translatedText,
      city: data?.data?.translations?.[2]?.translatedText,
      area: data?.data?.translations?.[3]?.translatedText,
    };
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPlaceAutoCompleteCity = async (input, userCountry) => {
  const country = userCountry.toLowerCase();
  const url = `${process.env.BACKEND_URL}api/v1/getGoogleAddress?input=${input}&country=${country}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data?.predictions?.[0]?.structured_formatting?.main_text?.replace(
      "-",
      " "
    );
  } catch (error) {
    console.error("Error fetching autocomplete data:", error);
  }
};
