import fetchWithHeaders from "../../fetchInterceptor";

// Function to fetch user data using access token
export const getPreferencesApi = async () => {
  const url = `${process.env.BACKEND_URL}api/v1/preferences/`;
  try {
    const response = await fetchWithHeaders(url, {
      method: "GET",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
// Function to update user data using access token
export const updatePreferencesApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}api/v1/preferences/`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "PUT",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
