export const SIDEBAR_CONTAINER =
  "flex flex-col  xl:w-[30%] 2xl:w-[30%]";
export const USER_NAME_CONTAINER = "mb-[30px]";
export const SELECTED_LINK_STYLE =
  " !border-New_Primary_Default !text-bold !text-New_Primary_Default";
export const BOTTOM_BORDER_CLASS = " border-b hover:border-New_Primary_Default";
export const MENU_LINK_CLASS =
  "px-4 flex justify-between gap-x-14 py-3 hover:text-New_Primary_Default text-Text_Gray ";
export const MENU_LINK_CUSTOM_TEXT_CLASS = "hover:!text-New_Primary_Default ";
export const SIDEBAR_SELECTED_LINK =
  " !text-New_Primary_Default !border-New_Primary_Default";
export const SIDEBAR_AVATAR_CONTAINER =
  " flex gap-x-3 items-center hover:text-New_Primary_Default focus:text-New_Primary_Default";
export const SIDEBAR_ITEM_CONTAINER =
  "hover:text-New_Primary_Default text-Text_Gray flex gap-x-10 justify-between items-center py-3 px-0 md:px-4  md:w-full";
