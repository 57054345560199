import React from "react";
import {
  BOTTOM_BORDER_CLASS,
  MENU_LINK_CUSTOM_TEXT_CLASS,
  SIDEBAR_AVATAR_CONTAINER,
  SIDEBAR_ITEM_CONTAINER,
  SIDEBAR_SELECTED_LINK,
} from "./styles";
import { DashboardRightArrowSVG } from "../../Atoms/SVGs";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { LOGOUT_TEXT } from "../../../public/Constants/EnumConstants";

const DashboardSideBarItem = ({ Icon, text, selected }) => {
  return (
    <div
      className={
        SIDEBAR_ITEM_CONTAINER +
        (selected ? SIDEBAR_SELECTED_LINK : null) +
        (text !== LOGOUT_TEXT ? BOTTOM_BORDER_CLASS : null)
      }
    >
      <div className={SIDEBAR_AVATAR_CONTAINER}>
        {Icon ? <Icon /> : null}
        <TypographyMontserrat
          fontWeight={selected ? "bold" : "medium"}
          size={"base"}
          text={text}
          color={selected ? "New_Primary_Default" : "inherit"}
          customStyle={MENU_LINK_CUSTOM_TEXT_CLASS}
        />
      </div>
      <div>{text !== LOGOUT_TEXT ? <DashboardRightArrowSVG /> : null}</div>
    </div>
  );
};

export default DashboardSideBarItem;
