import { resetUser } from "./userSlice";
import { resetBox } from "./boxOrderSlice";
import { resetPayment } from "./paymentSlice";
import { resetPreference } from "./preferencesSlice";
import { resetSurvey } from "./surveySlice";

export const resetAllSlices = () => (dispatch) => {
  dispatch(resetBox());
  dispatch(resetUser());
  dispatch(resetPayment());
  dispatch(resetSurvey());
  dispatch(resetPreference());
  window.location.href = "/login";
};
