// boxOrderSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ORDER_A_BOX_STEP_ONE, ORDER_A_BOX_STEP_TWO } from "./types";
import { HYDRATE } from "next-redux-wrapper";

import { orderABoxApi, orderABoxApiStepTwo } from "../app/api/boxOrder";
import { toast } from "react-toastify";
import { fetchUserData } from "./userSlice";

// Async thunk for fetching user data
export const orderABoxStepOne = createAsyncThunk(
  ORDER_A_BOX_STEP_ONE,
  async (payload, { rejectWithValue }) => {
    const response = await orderABoxApi(payload);
    if (response?.customer) {
      return response;
    }
    if (response?.error) {
      if (response?.error === "Customer has no address.") {
        toast("Oops! Your address needs a quick update. ", { type: "error" });
        return rejectWithValue(response?.error);
      } else if (Array.isArray(response?.error)) {
        toast(response?.error?.[0], { type: "error" });
        return rejectWithValue(response?.error?.[0]);
      }
    }
    return rejectWithValue(response?.error);
  }
);

export const orderABoxStepTwo = createAsyncThunk(
  ORDER_A_BOX_STEP_TWO,
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await orderABoxApiStepTwo(payload);
    if (response?.customer) {
      dispatch(fetchUserData());
      return response;
    }
    if (response?.error) {
      if (response?.error === "Customer has no address.") {
        toast("Oops! Your address needs a quick update. ", { type: "error" });
        return rejectWithValue(response?.error);
      } else if (Array.isArray(response?.error)) {
        toast(response?.error?.[0], { type: "error" });
        return rejectWithValue(response?.error?.[0]);
      }
    }
  }
);
const initialState = {
  box: null,
  loading: false,
  step: 1,
  boxOrderError: null,
};
// Slice for authentication and user data
const boxOrderSlice = createSlice({
  name: "boxOrder",
  initialState,
  reducers: {
    goBack: (state, action) => {
      state.step = 1;
    },

    changeOrderStep: (state, action) => {
      state.box = null;
      state.loading = false;
      state.step = action?.payload;
      state.boxOrderError = null;
    },
    resetBox: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action?.payload?.user,
        };
      })
      .addCase(orderABoxStepOne?.pending, (state) => {
        state.loading = true;
        state.boxOrderError = null;
      })
      .addCase(orderABoxStepOne?.fulfilled, (state, action) => {
        state.box = action?.payload;
        state.step = 2;
        state.loading = false;
        state.boxOrderError = null;
      })
      .addCase(orderABoxStepOne?.rejected, (state, action) => {
        state.loading = false;
        state.boxOrderError = action?.payload;
      })
      .addCase(orderABoxStepTwo?.pending, (state) => {
        state.loading = true;
        state.boxOrderError = null;
      })
      .addCase(orderABoxStepTwo?.fulfilled, (state, action) => {
        state.loading = false;

        state.step = 3;
        state.boxOrderError = null;
        state.box = action?.payload;
      })
      .addCase(orderABoxStepTwo?.rejected, (state, action) => {
        state.loading = false;
        state.boxOrderError = action?.payload?.error;
      });
  },
});
export const { changeOrderStep, goBack, resetBox } = boxOrderSlice?.actions;

export default boxOrderSlice?.reducer;
