import React from "react";
import { SIDEBAR_CONTAINER, USER_NAME_CONTAINER } from "./styles";
import SidebarLink from "./SidebarLink";
import { getSidebarMenuList } from "./constants";
import UserInitialsAvatar from "../UserInitialsAvatar";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_TEXT } from "../../../public/Constants/EnumConstants";
import { resetAllSlices } from "../../../store/combinedResetActions";

const DashboardSidebar = ({ setDashboardDrawerOpen }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const menuItems = getSidebarMenuList(userData?.payment_status);
  const userInitials = `${userData?.user?.first_name[0]?.toUpperCase()}${userData?.user?.last_name[0]?.toUpperCase()}`;
  return (
    <div className={SIDEBAR_CONTAINER}>
      <UserInitialsAvatar text={userInitials} />
      <div className={USER_NAME_CONTAINER}>
        <TypographyMontserrat
          align={"center"}
          color={"Text_Gray"}
          fontWeight={"light"}
          size={"base"}
          text={`${userData?.user?.first_name} ${userData?.user?.last_name}`}
        />
      </div>
      {menuItems?.map(({ icon, text, link }, index) => (
        <div
          key={index}
          onClick={() => {
            setDashboardDrawerOpen ? setDashboardDrawerOpen(false) : null;
            text === LOGOUT_TEXT ? dispatch(resetAllSlices()) : null;
          }}
        >
          <SidebarLink icon={icon} text={text} link={link} />
        </div>
      ))}
    </div>
  );
};

export default DashboardSidebar;
