// preferencesSlice.js
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import { FETCH_PREFERENCES, UPDATE_PREFERENCES } from "./types";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";
import {
  getPreferencesApi,
  updatePreferencesApi,
} from "../app/api/preferences";

// Async thunk for fetching user data
export const fetchPreferences = createAsyncThunk(
  FETCH_PREFERENCES,
  async (accessToken, { rejectWithValue }) => {
    const response = await getPreferencesApi();
    if (response) {
      return response;
    }
    return rejectWithValue(response?.detail);
  }
);

//UPDATE PREFERENCES
export const updatePreferences = createAsyncThunk(
  UPDATE_PREFERENCES,
  async (payload, { rejectWithValue }) => {
    const { data, type } = payload;
    const response = await updatePreferencesApi(data);
    if (response?.data) {
      toast("Profile updated successfully", { type: "success" });
      response.type = type;
      return response;
    }
    toast("There was a problem updating your profile", { type: "error" });
    return rejectWithValue(response);
  }
);
const initialState = {
  userPreferences: null,
  loading: false,
  error: null,
};

// Slice for authentication and user data
const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    resetPreference: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action?.payload?.user,
        };
      })
      .addCase(fetchPreferences?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPreferences?.fulfilled, (state, action) => {
        state.userPreferences = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPreferences?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updatePreferences?.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePreferences?.fulfilled, (state, action) => {
        state.loading = false;
        let userPreferencesUpdated = current(state)?.userPreferences;
        userPreferencesUpdated = {
          ...userPreferencesUpdated,
          [action?.payload?.type]: action?.payload?.data,
        };
        state.userPreferences = userPreferencesUpdated;
        state.error = { ...state?.error, updateProfile: null };
      })
      .addCase(updatePreferences?.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});
export const { resetPreference } = preferencesSlice?.actions;
export default preferencesSlice?.reducer;
